import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutUSPage = () => (
  <Layout title="About US">
    <Seo title="About US" />
    <section className="content-section">
      <div className="container" style={{marginTop:80}}>
        <h6>Charity</h6>
        <p>Welcome to 121Islam academy, probably the only 100% non profit online academy in the world.</p>
        <p>With 121Islam academy, you will not only be benefiting from learning the Quran, Arabic & Islamic Studies, but will also be working with us to help the poor and needy with the intention to gain the pleasure and reward of Allah. There are no hidden costs with us and we want to be 100% transparent with our customers. As a result we are detailing the company accounts as well as the profit distribution on our website on a monthly basis. You can see the same using the “charity” link provided on top right side of the site</p>
      </div>  
    </section>
    <section className="content-section">
      <div className="container">
        <h6>Our Story</h6>
        <p>We initially opened an Arabic school in New Cairo and noticed there was an increase with UK expats moving over to Egypt to learn Arabic, Qur’an and Islamic studies at our academy.</p>
        <p>We understood also that parents and students have busy schedules in the UK and USA, and trying to arrange lessons at the local mosque, Arabic centre or even to travel to the muslim lands to learn islamic studies isn’t always convenient and possible. So we wanted to cater for such students so we came up with the idea of providing quality Islamic studies (by our Native Arabic speaking teachers) to students in the UK through an online portal, working around their schedules with quality islamic teaching was key for this type of project.</p>
      </div>
    </section>  
    <section className="content-section">
      <div className="container">
        <blockquote class="card blockquote">
          <h6>Our Approach</h6>
          <p>We have a very-easy-to-understand approach to the way we teach. With several years of experience in our field coupled with steady growth, we have launched our services worldwide with the vision and aspirations to teach thousands of students all over the world.<br/>Our curriculum is built for all ages above 4 years old, with female and male teachers depending on your preference.</p>
        </blockquote>
      </div>
    </section>    
    <section className="content-section">
      <div className="container">
        <h6>Meet The Team</h6>
        <div class="card card-info">
          <h6 class="mb-1">Abu Hafsah</h6>
          <p class="mb-2 text-muted"><small>Founder / Managing partner</small></p>
          <p class="m-0">Abu Hafs is one of the co-founders at 121Islam who has graduated with a BSc in Physics and MSc in Medical Physics and worked as a Clinical Scientist at various hospitals in the uk. Abu Hafs wanted to fulfil his real desires of opening an academy to teach all ages Islamic subjects. His managerial skills and vision has allowed 121Islam to advance greatly by exceeding its expectations within the 1st year.</p>
        </div>
        <div class="card card-info bg-white">
          <h6 class="mb-1">Mohammed Shakil</h6>
          <p class="mb-2 text-muted"><small>Founder / Managing partner</small></p>
          <p class="m-0">Mohammed Shakil is a dedicated individual with numerous achievements in business. Starting off at an early age as a salesman, he was easily identified amongst his peers a strong businessman from the outset, Mohammed Shakil has a portfolio of business and realestate and has progressed tremendously in his career.</p>
        </div>
        <div class="card card-info">
          <h6 class="mb-1">Tamer Wahab</h6>
          <p class="mb-2 text-muted"><small>General Manager</small></p>
          <p class="m-0">Tamer is a highly focused individual with a portfolio of startups and business ventures. With a keen eye for detail, Tamer has played a key role in the structure of the academy with the nack for scrutinising and implementing smoother and automated processes and systems, always wanting to give the extra mile for our clients and provide an enjoyable and smooth experience.</p>
        </div>
      </div>
    </section>  
    <section className="content-section">
      <div className="container">
        <h6>Our Teachers</h6>
        <div className="teacher-card card">
          <div className="avatar mb-2">
            <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 30 }} />
          </div>
          <h6><strong>Ustaz Mayhoub Khalf</strong></h6>
          <p className="desc mb-2">Senior Tutor</p>
          <p className="mb-0"><strong>Years of Experience: </strong>3+ years</p>
          <p className="mb-0"><strong>Qualifications: </strong>ICDL</p>
          <p className="mb-0"><strong>Certificates: </strong>Bachelor of Islamic and Arabic Studies (University of Azhar)</p>
          <p className="mb-0"><strong>Specialties: </strong>Quran, Arabic and Islamic Sciences</p>
        </div>
        <div className="teacher-card card">
          <div className="avatar mb-2">
            <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 30 }} />
          </div>
          <h6><strong>Ustazah Mai Mohamed Ibrahim</strong></h6>
          <p className="desc mb-2">Senior Tutor</p>
          <p className="mb-0"><strong>Years of Experience: </strong>4+ years</p>
          <p className="mb-0"><strong>Qualifications: </strong>Grade 2 arabic studies (Azhar)</p>
          <p className="mb-0"><strong>Certificates: </strong>Diploma (University of Azhar)</p>
          <p className="mb-0"><strong>Specialties: </strong>Quran sciences and Arabic Language</p>
        </div>
        <div className="teacher-card card">
          <div className="avatar mb-2">
            <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 30 }} />
          </div>
          <h6><strong>Moaz sherief Mahmoud</strong></h6>
          <p className="desc mb-2">Senior Tutor</p>
          <p className="mb-0"><strong>Years of Experience: </strong>4+ years</p>
          <p className="mb-0"><strong>Qualifications: </strong>Ijazat fi riwayat (hfsa-shebta-qalun)</p>
          <p className="mb-0"><strong>Certificates: </strong>Bachelor of Islamic and Arabic Studies (University of Azhar)</p>
          <p className="mb-0"><strong>Specialties: </strong>ARABIC ,QURAN & Islamic studies</p>
        </div>
        <div className="teacher-card card">
          <div className="avatar mb-2">
            <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 30 }} />
          </div>
          <h6><strong>Maher Ibrahim</strong></h6>
          <p className="desc mb-2">Senior Tutor</p>
          <p className="mb-0"><strong>Years of Experience: </strong>1.5+ years</p>
          <p className="mb-0"><strong>Qualifications: </strong>Certified staff at Teaching Arabic to non-Arabic speakers -ICDL -JAVA</p>
          <p className="mb-0"><strong>Certificates: </strong>Faculty of Dar Al Uloom Cairo-Diploma in Arabic Language and Arts and Islamic Studies</p>
          <p className="mb-0"><strong>Specialties: </strong>ARABIC & QURAN</p>
        </div>
        <div className="teacher-card card">
          <div className="avatar mb-2">
            <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 30 }} />
          </div>
          <h6><strong>Rasha Saed Abdel Hamid</strong></h6>
          <p className="desc mb-2">Senior Tutor</p>
          <p className="mb-0"><strong>Years of Experience: </strong>1+ years</p>
          <p className="mb-0"><strong>Qualifications: </strong>IJAZIT ASSIM –Tajweed certificate in English from Prof. Karima Carroll book from the World Federation</p>
          <p className="mb-0"><strong>Certificates: </strong>Faculty of Engineering – Electric department</p>
          <p className="mb-0"><strong>Specialties: </strong>ARABIC & QURAN</p>
        </div>
        <div className="teacher-card card">
          <div className="avatar mb-2">
            <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 30 }} />
          </div>
          <h6><strong>Mohamed saed</strong></h6>
          <p className="desc mb-2">Senior Tutor</p>
          <p className="mb-0"><strong>Years of Experience: </strong>10+ years</p>
          <p className="mb-0"><strong>Qualifications: </strong>Ijazat fi riwayat (hfsa-Assem)</p>
          <p className="mb-0"><strong>Certificates: </strong>Faculty of Commerce & educational diploma in Arabic</p>
          <p className="mb-0"><strong>Specialties: </strong>ARABIC & QURAN</p>
        </div>
        <div className="teacher-card card">
          <div className="avatar mb-2">
            <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 30 }} />
          </div>
          <h6><strong>Fatimah Bahia Eldin</strong></h6>
          <p className="desc mb-2">Senior Tutor</p>
          <p className="mb-0"><strong>Years of Experience: </strong>2 years</p>
          <p className="mb-0"><strong>Qualifications: </strong>Professional in reciting Quran , Teaching Arabic for foreign (Non Arabic speaking Muslims)</p>
          <p className="mb-0"><strong>Certificates: </strong>Faculty of Pharmacy</p>
          <p className="mb-0"><strong>Specialties: </strong>Quran, Arabic and Islamic studies</p>
        </div>
        <div className="teacher-card card">
          <div className="avatar mb-2">
            <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 30 }} />
          </div>
          <h6><strong>Mahmoud mohamed</strong></h6>
          <p className="desc mb-2">Senior Tutor</p>
          <p className="mb-0"><strong>Years of Experience: </strong>1 year</p>
          <p className="mb-0"><strong>Qualifications: </strong>Ijazat fi riwayat (hfsa-Assem) , Bukhary and Muslim as well</p>
          <p className="mb-0"><strong>Certificates: </strong>Faculty of languages Department of Islamic Studies in English</p>
          <p className="mb-0"><strong>Specialties: </strong>Quran, Arabic and Islamic studies</p>
        </div>
      </div>
    </section> 
  </Layout>
)

export default AboutUSPage
